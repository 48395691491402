import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

const AccountHeading = ({ profile, location }) => {

	return (<header className="font-size--small">		
		<p>Welcome back {profile.firstname} {profile.lastname}, please update your information below. Here you will also be able to access your VIP pass and RSVP for events one month prior to the fair.</p>

		<nav className="mt--2">
			{ 1 > 2 && profile.ticket && profile.ticket.links && <a href={profile.ticket.links.wallet} target="_blank" className="full_button blue_btn mb--1_2">Untitled Art Miami Beach 2023 VIP Pass</a>	}
			{ 1 > 2 && <Link className="full_button mb--1_2" to="/program">VIP Program</Link>	}
			{ 1 > 2 && profile.links && profile.links.events && <a href={profile.links.events} target="_blank" className="full_button blue_btn mb--1_2">Untitled Art Miami Beach 2023 Calendar</a>	}
		</nav>
		

	</header>)

}

export default AccountHeading